import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoutes from "./helper/PrivateRoutes.js";
import TranscriberRegister from "./components/Register";
import TranscriberLogin from "./components/login";
import Forgotpassword from "./components/forgotpassword";
import Resetpassword from "./components/resetpassword";
import "./assets/style/App.sass";
import Dashboard from "./components/dashboard";
import MsEditor from "./components/mseditor";
import GgEditor from "./components/ggeditor";
import GGRTEditor from "./components/ggrteditor";
import GGRTEditorPhase2 from "./components/ggrteditor_phase2";
import AISGEditor from "./components/aisgeditor";
import CPSPKEditor from "./components/cp_spk_editor";
import CPEditor from "./components/cp_editor";
import CompletedJob from "./components/completed-job";
import JobHistory from "./components/job-history";
import ErrorList from "./components/ErrorList.jsx";
import MyProfile from "./components/myProfile";
import ChangePassword from "./components/change-password";
import ChangeSuperPassword from "./components/change-superpassword";
import AdmPRDashboard from "./components/admprdashboard";
import AdmPRFileList from "./components/admprfiles";
import AdmPREditor from "./components/admpreditor";

import { logout } from "./actions/userActions";
import jwt_decode from "jwt-decode";

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (userLogin.token) {
      const decoded = jwt_decode(userLogin.token);

      //check for expire token
      const currentTime = Date.now() / 1000; //to get milliseconds
      if (decoded.exp < currentTime) {
        //Logout user
        dispatch(logout());
      }
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={TranscriberLogin} />
          <Route
            exact
            path="/transcriberregister"
            component={TranscriberRegister}
          />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <Route exact path="/forgotpassword" component={Forgotpassword} />
          <Route
            exact
            path="/resetpassword/:resettoken"
            component={Resetpassword}
          />
          <PrivateRoutes
            exact
            path="/changepassword"
            component={ChangePassword}
          />
          <PrivateRoutes
            exact
            path="/changesuperpassword"
            component={ChangeSuperPassword}
          />
          <PrivateRoutes exact path="/ms_editor/:id" component={MsEditor} />
          <PrivateRoutes exact path="/gg_editor/:id" component={GgEditor} />
          <PrivateRoutes exact path="/ggrt_editor/:id" component={GGRTEditor} />
          <PrivateRoutes
            exact
            path="/ggrt_editorphase2/:id"
            component={GGRTEditorPhase2}
          />

          <PrivateRoutes exact path="/aisg_editor/:id" component={AISGEditor} />
          <PrivateRoutes
            exact
            path="/cp_spk_editor/:id"
            component={CPSPKEditor}
          />
          <PrivateRoutes exact path="/cp_editor/:id" component={CPEditor} />
          <PrivateRoutes exact path="/completedjob" component={CompletedJob} />
          <PrivateRoutes exact path="/jobhistory" component={JobHistory} />
          <PrivateRoutes exact path="/Errorlist" component={ErrorList} />
          <PrivateRoutes exact path="/myprofile" component={MyProfile} />
          <PrivateRoutes
            exact
            path="/admprdashboard"
            component={AdmPRDashboard}
          />
          <PrivateRoutes
            exact
            path="/admprfilelist"
            component={AdmPRFileList}
          />
          <PrivateRoutes
            exact
            path="/admpreditor/:id"
            component={AdmPREditor}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
