import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "./Table";
import { format } from "date-fns";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Alert, CustomDialog, Confirm } from "react-st-modal";
import PopupRejectFile from "../Popups/PopupRejectFile";
import { Link } from "react-router-dom";
import PopupHandleBy from "../Popups/PopupHandleBy.jsx";
import {
  AcceptFillIcon,
  AcceptIcon,
  AccordionArrow,
  PopupClose,
  RejectFillIcon,
  RejectIcon,
} from "../icons.component";

const TableList = (props) => {
  const [datalist, setDataList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [currentRowId, setCurrentRowId] = useState();
  const [urlPath, setUrlPath] = useState();

  const history = useHistory();
  //const { url } = props.selectedRow;
  // const [urlDateTime, setUrlDateTime] = useState(new Date());
  // const [expiryDateTime, setExpiryDateTime] = useState(new Date());

  const userLogin = useSelector((state) => state.userLogin);
  const vendType = userLogin.user.vendType;

  //console.log("vendType", vendType)

  const {
    id,
    src,
    reload,
    pagination,
    hideTargetDownload,
    hideInvoiceDownload,
    hideReceiptDownload,
    parent,
  } = props;

  const spanLinkStyle = {
    cursor: "pointer",
    color: "#22aaf0",
    textDecoration: "underline",
    fontWeight: "bold",
  };
  let columns = [];
  if (src == "currentongoingjoblist" || src === "currentongoingjoblistWithName") {
    columns = [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span
            className={`${row.isExpanded ? "expanded" : "collapsed"}`}
            {...row.getToggleRowExpandedProps()}>
            <AccordionArrow />
          </span>
        ),
      },
      {
        Header: "File Name",
        accessor: "fileName",
        //className: 'col-firstName',
        Cell: ({ row }) => {
          return  <span
          style={spanLinkStyle}
          onClick={() => rowClicked(row.original)}>
          {row.original.fileName}
        </span>
        },
      },
      {
        Header: "Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
        //className: 'col-lastName',
      },
      {
        Header: "Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        //className: 'col-email',
      },
      {
        Header: "Status",
        //accessor: "transcriberStatus",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return row.original.vendType == "Proof Reader" ? (
            <label>Proof Reading {row.original.proofReaderStatus}</label>
          ) : row.original.vendType == "Transcriber" ? (
            <label>Transcription {row.original.transcriberStatus}</label>
          ): row.original.stage == "Proof Reading" ? (
            <label>Proof Reading {row.original.proofReaderStatus}</label>
          ): <label>Transcription {row.original.transcriberStatus}</label>
        },
      },
      // {
      //   Header: "ETA",
      //   accessor: "ETA",
      //   sortType: "basic",
      //   filter: "text",
      // },
      {
        Header: "Assigned On",
        accessor: "assignedOn",
        sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
      },
      {
        Header: "Accepted On",
        accessor: "acceptedOn",
        //sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
      },
      {
        Header: "Preview",
        accessor: "fileLocation",
        //sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
        Cell: ({ row }) => {
          return (
            <div>
              <button
                className="previewBtn"
                onClick={(e) => previewAudioFile(row.original.fileLocation, row.original.fileName, e)}>
                Preview
              </button>
            </div>
          );
        },
      },
      {
        Header: "Handled By",
        accessor: "handledBy",
        //sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
        Cell: ({ row, value }) => {
          return (
            <div onClick={() => onHandledBy(row.original)}>
              {value === "" ? "No Info" : value}
            </div>
          );
        },
      },
      // {
      //   Header: "Accept/Reject",
      //   Cell: ({ row }) => {
      //     return (
      //       <div className="d-flex">
      //         <button
      //           disabled={
      //             row.original.stage == "Transcription" ||
      //             row.original.stage == "Proof Reading"
      //           }
      //           style={{
      //             opacity:
      //               row.original.stage == "Transcription" ||
      //               row.original.stage == "Proof Reading"
      //                 ? 0.25
      //                 : 1,
      //           }}
      //           onClick={(e) => onAcceptFile(row.original, e)}
      //           className="acceptBtn">
      //           <AcceptIcon />
      //           <AcceptFillIcon />
      //         </button>
      //         <button
      //           className="rejectBtn"
      //           disabled={
      //             row.original.stage == "Transcription" ||
      //             row.original.stage == "Proof Reading"
      //           }
      //           style={{
      //             opacity:
      //               row.original.stage == "Transcription" ||
      //               row.original.stage == "Proof Reading"
      //                 ? 0.25
      //                 : 1,
      //           }}
      //           onClick={async (e) => {
      //             const result = await CustomDialog(
      //               <PopupRejectFile
      //                 fid={row.original.id}
      //                 stage={row.original.stage}
      //                 custtype={row.original.custType}
      //               />,
      //               {
      //                 title: "Reject File",
      //                 showCloseIcon: true,
      //               }
      //             );
      //           }}>
      //           <RejectIcon />
      //           <RejectFillIcon />
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ];
  }
  else if (src == "currentnewjoblist") {
    columns = [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span
            className={`${row.isExpanded ? "expanded" : "collapsed"}`}
            {...row.getToggleRowExpandedProps()}>
            <AccordionArrow />
          </span>
        ),
      },
      {
        Header: "File Name",
        accessor: "fileName",
        //className: 'col-firstName',
        Cell: ({ row }) => {
          return <label>{row.original.fileName}</label>
        },
      },
      {
        Header: "Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
        //className: 'col-lastName',
      },
      {
        Header: "Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        //className: 'col-email',
      },
      {
        Header: "Status",
        //accessor: "transcriberStatus",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return row.original.vendType == "Proof Reader" ? (
            <label>Proof Reading {row.original.proofReaderStatus}</label>
          ):row.original.vendType == "Transcriber"? (
            <label>Transcription {row.original.transcriberStatus}</label>
          ):<label> {row.original.stage} </label>;
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Assigned On",
        accessor: "assignedOn",
        sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
      },
      // {
      //   Header: "Amount",
      //   accessor: "amount",
      //   //sortType: "basic",
      //   //filter: "text",
      //   //className: 'col-salary',
      // },
      {
        Header: "Preview",
        accessor: "fileLocation",
        //sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
        Cell: ({ row }) => {
          return (
            <div>
              <button
                className="previewBtn"
                onClick={(e) => previewAudioFile(row.original.fileLocation, row, e)}>
                Preview
              </button>
            </div>
          );
        },
      },
      {
        Header: "Accept/Reject",
        Cell: ({ row }) => {
          return (
            <div className="d-flex">
              <button
                disabled={
                  row.original.stage == "Transcription" ||
                  row.original.stage == "Proof Reading"
                }
                style={{
                  opacity:
                    row.original.stage == "Transcription" ||
                    row.original.stage == "Proof Reading"
                      ? 0.25
                      : 1,
                }}
                onClick={(e) => onAcceptFile(row.original, e)}
                className="acceptBtn">
                <AcceptIcon />
                <AcceptFillIcon />
              </button>
              <button
                className="rejectBtn"
                disabled={
                  row.original.stage == "Transcription" ||
                  row.original.stage == "Proof Reading"
                }
                style={{
                  opacity:
                    row.original.stage == "Transcription" ||
                    row.original.stage == "Proof Reading"
                      ? 0.25
                      : 1,
                }}
                onClick={async (e) => {
                  const result = await CustomDialog(
                    <PopupRejectFile
                      fid={row.original.id}
                      stage={row.original.stage}
                      custtype={row.original.custType}
                    />,
                    {
                      title: "Reject File",
                      showCloseIcon: true,
                    }
                  );
                }}>
                <RejectIcon />
                <RejectFillIcon />
              </button>
            </div>
          );
        },
      },
    ];
  }else if (src == "JobHistory") {
    columns = [
      {
        Header: "File Name",
        accessor: "fileName",
      },
      {
        Header: "Transcription Time",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Status",
        //: "transcriberStatus",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return row.original.vendType == "Proof Reader" ? (
            <label>Proof Reading {row.original.proofReaderStatus}</label>
          ) : (
            <label>Transcription {row.original.transcriberStatus}</label>
          );
        },
      },
      {
        Header: "Comment",
        accessor: "comment",
        sortType: "basic",
        filter: "text",
      },
    ];
  } else if (src == "completedJob") {
    columns = [
      {
        Header: "File Name",
        accessor: "fileName",
      },
      {
        Header: "Transcription Time",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Status",
        //accessor: "transcriberStatus",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return row.original.vendType == "Proof Reader" ? (
            <label>Proof Reading</label>
          ) : row.original.vendType == "Transcriber" ? (
            <label>Transcription</label>
          ) : row.original.proofReaderStatus && row.original.proofReaderStatus == "Completed" ? (
            <label>Proof Reading</label>
          ) : <label>Transcription</label>
        },
      },
      {
        Header: "Completed On",
        //accessor: "transcriberStatus",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return row.original.vendType == "Proof Reader" ? (
            <label>{row.original.proofReadingcompletedOn}</label>
          ) : row.original.vendType == "Transcriber" ? (
            <label>{row.original.transcriptioncompletedOn}</label>
          ) : row.original.proofReaderStatus && row.original.proofReaderStatus == "Completed" ? (<label>{row.original.proofReadingcompletedOn}</label>
          ) : <label>{row.original.transcriptioncompletedOn}</label>
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
      },
    ];
  }else if (src === "customerprojects") {
    columns = [
      //expandColumn,
      {
        Header: "Project Name",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
        <Link to={{pathname:"/viewprojectlangs",state:{id:row.original._id,type:row.original.type}}}>
          <span> {row.original.projectName}</span>
        </Link>
        ),
      },
      {
        Header: "Source Lang",
        accessor: "srcLang",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Audio Lentgh",
        accessor: "length",
        sortType: "basic",
        filter: "text",
       
      },
      {
        Header: "ETA",
        accessor:"ETA",
        Cell: ({ value, row, cell }) => (
         <label>{format(new Date(row.original.ETA), "dd/MM/yyyy")}</label>
          ),
      },
      {
        Header: "Status",
        accessor:"status",
      },
           
    ];
  }
  else if(src=="admprjoblist")
  {
    columns = [
      {
        Header: "File Name",
        accessor: "fileName",
        //className: 'col-firstName',
        Cell: ({ row }) => {
          return  <span
          style={spanLinkStyle}
          onClick={() => admPRrowClicked(row.original)}>
          {row.original.fileName}
        </span>
        },
      },
      {
        Header: "Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
        //className: 'col-lastName',
      },
      {
        Header: "Status",
        accessor: "stage",
        sortType: "basic",
        filter: "text",
        // Cell: ({ row }) => {
        //   return row.original.stage == "Transcription" || row.original.stage == "Proof Reading" ? (
        //     <label> {`InProgress ${row.original.stage}`} </label>
        //   ) : row.original.stage == "Open" && row.original.transcriber
        //     ? (<label>{row.original.stage} Rejected </label>)
        //     :(<label>{row.original.stage} </label>);
        // },
        //className: 'col-email',
      },
      {
        Header: "Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        //className: 'col-email',
      },
      {
        Header: "Transcriber",
        accessor: "transcriber",
        sortType: "basic",
        filter: "text",
        //className: 'col-email',
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Assigned On",
        accessor: "assignedOn",
        sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
      },

    ]
  }

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      
      <div className="detailsDrop">
        <div className="d-flex firstrow">
          <div className="deatilsSection">
            <div className="deatilsLabel">Speaker Tagging:</div>
            <div className="detailsValue">{row.original.speakerTag}</div>
          </div>

          <div className="deatilsSection">
            <div className="deatilsLabel">Time Stamping:</div>
            <div className="detailsValue">{row.original.timeStamping}</div>
          </div>

          <div className="deatilsSection">
            <div className="deatilsLabel">Percentage of Completion:</div>
            <div className="detailsValue">{row.original.percentageOfCompletion}</div>
          </div>
        </div>

        <div>
          <div className="deatilsLabel">Instruction:</div>
          <div className="detailsValue">{row.original.instructions}</div>
        </div>
        {console.log("row", row)}
      </div>
    ),
    []
  );

  let title =
        src == "currentongoingjoblist"
        || src === "currentongoingjoblistWithName"
        ? "Ongoing Jobs"
        :src == "currentnewjoblist"
        ? "New Jobs"
        :src == "JobHistory"
        ? "Job History"
        :src == "completedJob"
        ? "Completed Projects"
        :src=="admprjoblist"
        ?"File List"
        : "";

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
    // disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());
 
    document.onkeydown = function (e) {
 
        // disable F12 key
        if(e.keyCode == 123) {
            return false;
        }
 
        // disable I key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
            return false;
        }
 
        // disable J key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            return false;
        }
 
        // disable U key
        if(e.ctrlKey && e.keyCode == 85) {
            return false;
        }
    }
  },[])
  
  useEffect(() => {
    let url = "";

    const headers = reqHeader(true, true, true);
    const params = { type: vendType };
    const config = { headers, params };

    async function getTableData() {
      if (src == "currentongoingjoblist" || src === "currentongoingjoblistWithName") {
        url = `${process.env.REACT_APP_URL}/transcription/transcription/currentfilelist`;
        console.log("Inside current ongoing jobs");
        await axios
          .get(url, config)
          .then((res) => {
            //console.log("ongoing res Tablelist..", res);
            const sorted =  res.data.data.sort((a,b)=>{
              return new Date(b.acceptedOn) - new Date(a.acceptedOn);
            })
           
            let tabledata=sorted.map((row) => {
              if(row.transcriberStatus == "Accepted" ||
              row.transcriberStatus == "In Progress" ||
              row.proofReaderStatus == "Accepted" ||
              row.proofReaderStatus == "In Progress")
              {
               return {
                  id: row.id,
                  fileName: row.fileName,
                  fileDuration: row.fileDuration,
                  tgtLang: row.tgtLang,
                  vendType: row.vendType,
                  transcriberStatus:row.transcriberStatus,
                  proofReaderStatus:row.proofReaderStatus,
                  stage: row.stage,
                  ETA: format(new Date(row.ETA), "dd/MM/yyyy"),
                  assignedOn: format(
                    new Date(row.assignedOn),
                    "dd/MM/yyyy"
                  ),
                  amount: row.amount,
                  fileLocation: row.fileLocation,
                  instructions: row.instructions,
                  timeStamping: row.timeStamping,
                  speakerTag: row.speakerTag,
                  percentageOfCompletion:row.percentageOfCompletion,
                  custName: row.custName,
                  custCode: row.custCode,
                  custType: row.custType,
                  handledBy: row.handledBy,
                  acceptedOn: format(
                    new Date(row.acceptedOn),
                    "dd/MM/yyyy"
                  ),
               }
                
              }
            else{
              return undefined;
            }

            });
            
            //ongoingtabledata.push(rowdata);
            tabledata=tabledata.filter(item=>item)
            console.log("tabledata...",tabledata);
            // let tabledata =rowdata;
            // console.log("ongoing tabledata..", tabledata);
            setDataList(tabledata);
            setDataCount(tabledata.length);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
          });
      }else if (src == "currentnewjoblist")
       {
        url = `${process.env.REACT_APP_URL}/transcription/transcription/currentfilelist`;

        await axios
          .get(url, config)
          .then((res) => {
            console.log("res Tablelist..", res);
            let tabledata=res.data.data.map((row) => {
              // if(row.transcriberStatus != "Accepted" &&
              // row.transcriberStatus != "In Progress" &&
              // row.transcriberStatus != "Completed" &&
              // row.proofReaderStatus != "Accepted" &&
              // row.proofReaderStatus != "In Progress" &&
              // //row.proofReaderStatus != "Rejected Reassign" &&
              // row.proofReaderStatus != "Completed")
              if(row.transcriberStatus == "Assigned" || row.proofReaderStatus == "Assigned")
              {
               return {
                  id: row.id,
                  fileName: row.fileName,
                  fileDuration: row.fileDuration,
                  tgtLang: row.tgtLang,
                  vendType: row.vendType,
                  transcriberStatus: row.transcriberStatus,
                  proofReaderStatus: row.proofReaderStatus,
                  stage: row.stage,
                  ETA: format(new Date(row.ETA), "dd/MM/yyyy"),
                  assignedOn: format(
                    new Date(row.assignedOn),
                    "dd/MM/yyyy"
                  ),
                  amount: row.amount,
                  fileLocation: row.fileLocation,
                  instructions: row.instructions,
                  timeStamping: row.timeStamping,
                  speakerTag: row.speakerTag,
                  percentageOfCompletion:row.percentageOfCompletion,
                  custName: row.custName,
                  custCode: row.custCode,
                  custType: row.custType,
               }
                
              }
              else{
                return undefined;
              }
  
              });
              
              //ongoingtabledata.push(rowdata);
              tabledata=tabledata.filter(item=>item)
              console.log("tabledata...",tabledata);
            setDataList(tabledata);
            setDataCount(tabledata.length);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
          });
      }else if (src == "JobHistory") {
        url = `${process.env.REACT_APP_URL}/transcription/transcription/gethistory/`;
        await axios
          .get(url, config)
          .then((res) => {
            console.log("res Tablelist..", res);
            let tabledata = res.data.data.map((row) => {
              return {
                id: row.subRows._id,
                fileName: row.subRows.fileName,
                fileDuration: row.subRows.fileDuration,
                tgtLang: row.subRows.tgtLang,
                vendType: row.subRows.vendType,
                transcriberStatus: row.subRows.transcriberStatus,
                proofReaderStatus: row.subRows.proofReaderStatus,
                comment:
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
              };
            });
            console.log("history tabledata..", tabledata);
            setDataList(tabledata);
            setDataCount(tabledata.length);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
          });
      } else if (src == "completedJob") {
        url = `${process.env.REACT_APP_URL}/transcription/transcription/getcompletedfiles`;

        await axios
          .get(url, config)
          .then((res) => {
            console.log("getcompletedfiles..", res);
            let tabledata = res.data.data.map((row) => {
              return {
                id: row.subRows._id,
                fileName: row.subRows.fileName,
                fileDuration: row.subRows.fileDuration,
                tgtLang: row.subRows.tgtLang,
                vendType: row.subRows.vendType,
                transcriberStatus: row.subRows.transcriberStatus,
                proofReaderStatus: row.subRows.proofReaderStatus,
                transcriptioncompletedOn: row.subRows.transcriptioncompletedOn && format(new Date(row.subRows.transcriptioncompletedOn), "dd/MM/yyyy"),
                proofReadingcompletedOn: row.subRows.proofReadingcompletedOn && format(new Date(row.subRows.proofReadingcompletedOn), "dd/MM/yyyy"),
                ETA: format(new Date(row.subRows.ETA), "dd/MM/yyyy"),
              };
            });
            console.log("tabledata..", tabledata);
            setDataList(tabledata);
            setDataCount(tabledata.length);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
          });
      }else if(src=="admprjoblist")
      {
        console.log("id, domain,lang",id);
        const params={
          pid:id[0],
          domain:id[1],
          lang:id[2],
          freq:id[3],
        }
        const config = { headers, params };
        url = `${process.env.REACT_APP_URL}/transcription/transcription/admprfilelist`;
        console.log("Inside admprjoblist jobs");
        await axios
          .get(url, config)
          .then((res) => {
            console.log("ongoing res Tablelist..", res);
            let tabledata=res.data.data.map((row) => {
              console.log("Inside tablelist loop");
              console.log("row inside loop",row);
              let transcribername=row.transcription.map((cur)=>{
                if(cur.docStaus!="Rejected"||cur.docStaus!="Cancelled")
                {
                  return cur.email;
                }
                else
                {
                  return "";
                }
              });
               console.log("transcribername", transcribername)
              let stage = row.stage == "Transcription" || row.stage == "Proof Reading" ? (`${row.stage} In Progress`) 
              : row.stage == "Open" && transcribername.length != 0
              ? (`${row.stage} Rejected`)
              : (row.stage);
              console.log("stage", stage)

               return {
                id:row._id,
                projectName:row.projectName,
                fileName:row.fileName,
                fileDuration:row.fileDuration,
                tgtLang:row.tgtLang,
                domain:row.domain,
                frequency:row.frequency,
                stage:stage,
                ETA:format(new Date(row.ETA), "dd/MM/yyyy"),
                transcriber:transcribername,
            }
             
            });
            
            tabledata=tabledata.filter(item=>item)
            console.log("tabledata...",tabledata);
            
            setDataList(tabledata);
            setDataCount(tabledata.length);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
          });
      }
    }

    getTableData();
  }, []);

  const previewAudioFile = (rowFileLocation, rowFileName, e) => {
    //console.log("file Location", rowFileLocation, rowFileName);
    const previewAudioFileurl = `${process.env.REACT_APP_URL}/transcription/transcription/previewAudioFiles`;
    const headers = reqHeader(true, true, true);
    console.log("file Location", rowFileLocation);
    //let revisedUrl = rowFileLocation.replace("/TexLang-Cloud-Storage/", "/");
    //console.log("revisedUrl", revisedUrl);
    let revisedUrl = process.env.REACT_APP_ENVIRONMENT === "production"
    ? rowFileLocation.replace("/texlang-cloud-storage/", "/")
    : rowFileLocation.replace("/staging-texlang-cloudstorage/", "/");
    console.log("revisedUrl", revisedUrl);
    const params = {
      fpath: revisedUrl,
      type: "Transcription",
    };

    const config = { headers, params };
    //console.log("config", config);

    axios
      .get(previewAudioFileurl, config)
      .then((response) => {
        const { url } = response.data.data;
        //setUrlPath(url);
        //console.log("Signed URL", url)
       
          Alert(
            <div className="previewPop">
              <header className="popupHead">
                <h3>Preview</h3>
              </header>
              <AudioPlayer
                autoPlay={false}
                src={url}
                controls={true}
              />
            </div>
          )

          // //Change audio url code
          // let newfp;
          // let filename=rowFileName;
          // console.log("fname...",filename);
          // if(filename)
          // {
          // let randomn=Math.random();
          // let splitpath=filename.split(".");
          // let newpath=splitpath[0]+randomn;
          
          // newfp=url.replace(splitpath[0],newpath);
          // console.log("new Path is..",newfp);
          // setUrlPath(newfp);
          // }
          // console.log("Signed URL newfp", newfp)
      })
      .catch((err) => {});
  };

  const onAcceptFile = async (row, e) => {
    e.preventDefault();
    console.log("Accept row..", row);
    const result = await Confirm(
      "Are you sure you want to Accept this file? Please Preview the file before Accepting."
    );
    if (result) {
      const headers = reqHeader(true, true, true);

      let body = {
        fid: row.id,
        stage: row.stage,
      };
      const config = { headers };
      let url = `${process.env.REACT_APP_URL}/transcription/transcription/updtfileacceptstat`;

      await axios.put(url, body, config).then((res) => {
        console.log("axios res...", res);
        toast.success(res.data.msg);
        window.location.reload();
      });
    } else {
    }
  };

  const rowClicked = (row) => {
    console.log("Row inside the rowClicked is ", row);

    const { custCode,speakerTag } = row;

    //http://localhost:3000/ms_editor/614c21dcf28517225c58686d

    let client =
      custCode === "MS"
        ? "ms_editor"
        : custCode === "AZ"
        ? "amz_editor"
        : custCode === "GG"
        ? "gg_editor" 
        : custCode === "GGRT" 
        ? "ggrt_editor"
        : custCode === "GGRTPH2"
        ? "ggrt_editorphase2"
        : custCode === "AISG" ? "aisg_editor" 
        : (custCode === "CP" && speakerTag=="0")? "cp_editor"
        : custCode === "CP" && speakerTag!="0" ? "cp_spk_editor":
         "cust_editor";

    let childWindow = window.open(`/${client}/${row.id}`, "_blank");

    var timer = setInterval(function () {
      if (childWindow.closed) {
        clearInterval(timer);
        reload(true);
      }
    }, 1000);
  };

  const admPRrowClicked = async (row) => {
    console.log("Row inside the rowClicked is ", row);
    const { id,custCode } = row; //618a2ab74aaef50aa8c2d509
    let client ="admpreditor";
    let childWindow = window.open(`/${client}/${id}`, "_blank");
    var timer = setInterval(function () {
      if (childWindow.closed) {
        clearInterval(timer);
        reload(true);
      }
    }, 1000);
  };

  const onHandledBy = async (row) => {
    if (src === "currentongoingjoblistWithName") {
      console.log("Inside the Table List onHandledBy !!!", row);
      const result = await CustomDialog(
        <PopupHandleBy
          fid={row.id}
          stage={row.stage}
          custtype={row.custType}
        />,
        {
          title: "Add Details",
          showCloseIcon: true,
        }
      );
    }
  };

  return (
    <Table
      columns={columns}
      data={datalist}
      renderRowSubComponent={renderRowSubComponent}
      title={title}
      count={dataCount}
      filter={src == "currentjoblist" ? false : true}
      pagination={pagination}
      hideTargetDownload={hideTargetDownload}
      hideInvoiceDownload={hideInvoiceDownload}
      hideReceiptDownload={hideReceiptDownload}
    />
  );
};

export default React.memo(TableList);
